@import url("https://fonts.googleapis.com/css2?family=Baumans&family=Urbanist&display=swap");
*,
body,
::after,
::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  /* font-family: Urbanist; */
  font-family: "Trebuchet MS", Tahoma, sans-serif;
  scroll-behavior: smooth;
}

:root {
  --primary-blue: #2e368f; /*rgb(46, 54, 143)*/
  --primary-purple: #cb6ce6; /*rgb(203, 108, 230)*/
  /* --primary-purple-light: rgb(226, 215, 228); rgb(203, 108, 230) */
  /* --primary-purple-light: rgb(221, 213, 223); */
  --primary-purple-light: rgb(226, 221, 228);
  --primary-purple-400: rgba(203, 108, 230, 0.4); /*rgb(203, 108, 230)*/
  --primary-grey: #d9d9d8;
  --primary-white: #ffffff;
  --transition: all 0.2s linear;
  --transition-move: all 0.4s linear;
  --transition-slow: all 0.6s ease-out;
  --transition-text: all 1.2s ease-in-out;
  --transition-text-slow: all 1.4s ease-in-out;
  --padding-main: 130px;
  --padding-tablet: 50px;
  --padding-small: 25px;
}

.whatsapp-widget ._1yCVn,
._--3fm,
._2qp0Z {
  right: 0;
  left: 25px;
  z-index: 9999999999;
}
