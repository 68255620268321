.error {
  //   height: 100vh;
  padding: 50px var(--padding-main);
  display: flex;
  flex-direction: column;
  gap: 40px;
  //   background: white;

  & img {
    width: 100%;
    // height: 80%;
  }
  & .back {
    padding: 20px;
    background: var(--primary-blue);
    color: white;
    font-weight: 400;
    letter-spacing: 1px;
    text-decoration: none;
    width: fit-content;
    border-radius: 5px;
  }

  & h1 {
    font-size: 35px;
    letter-spacing: 1px;
    color: var(--primary-blue);
  }
}
