.corporate {
  background: white;
  min-height: 100vh;
  //   border: 2px solid red;

  & .topmost {
    // border: 2px solid blue;
    height: 50vh;
    width: 100%;
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position-y: 20%;
    // object-fit: contain;
    z-index: 1;
    position: relative;
    display: flex;
    align-items: center;

    & .image {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;

      & img {
        object-fit: cover;
      }
    }

    & h1 {
      position: relative;
      z-index: 3;
      color: white;
      font-size: 35px;
      letter-spacing: 1px;
      padding: 0 var(--padding-main);
      transform: translateX(0);
      opacity: 1;
      transition: var(--transition-text);

      @media (max-width: $breakpoint-tablet) {
        font-size: 30px;
        padding: 0 var(--padding-tablet);
      }
      @media (max-width: $breakpoint-small) {
        font-size: 25px;
      }
    }
    &:before {
      content: "";
      position: absolute;
      z-index: 2;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      // background-color: rgba(0, 0, 0, 0.5);

      background: rgb(46, 54, 143);
      background: linear-gradient(
        90deg,
        rgba(46, 54, 143, 0.65) 0%,
        rgba(0, 0, 0, 0.65) 90%
      );
    }
  }

  & .translated {
    & h1 {
      transform: translateX(-50%);
      opacity: 0;
      transition: var(--transition-text);
    }
    // & p {
    //   transform: translateX(-50%);
    //   opacity: 0;
    //   transition: var(--transition-text-slow);
    // }
  }

  & .content {
    // border: 2px solid red;
    display: flex;
    // justify-content: center;
    padding: 50px 0;
    align-items: center;
    flex-direction: column;
    gap: 30px;

    @media (max-width: $breakpoint-tablet) {
      padding: 50px var(--padding-tablet);
    }

    & h1 {
      font-size: 25px;
      color: var(--primary-blue);
      // border: 1px solid red;

      @media (max-width: $breakpoint-tablet) {
        font-size: 23px;
      }
      @media (max-width: $breakpoint-small) {
        font-size: 20px;
        width: 100%;
      }
    }

    & .items {
      display: flex;
      //   border: 2px solid blue;
      gap: 10px;
      flex-wrap: wrap;
      width: 50%;
      align-items: center;
      justify-content: center;

      @media (max-width: $breakpoint-tablet) {
        width: 100%;
        align-items: flex-start;
        justify-content: flex-start;
      }
      @media (max-width: $breakpoint-small) {
        width: 100%;
        flex-direction: column;
        gap: 15px;
        align-items: flex-start;
        justify-content: flex-start;
      }

      & .item {
        // border: 2px solid red;
        display: inline-flex;
        gap: 5px;
        align-items: center;
        width: max-content;
        font-size: 14px;
        font-weight: 400;

        @media (max-width: $breakpoint-tablet) {
          font-size: 12px;
        }
        @media (max-width: $breakpoint-small) {
          font-size: 10px;
        }

        & div {
          background: var(--primary-purple);
          //   padding: 5px 5px;
          height: 10px;
          width: 10px;
          border-radius: 50%;
        }
      }
    }

    & p {
      font-size: 14px;

      @media (max-width: $breakpoint-tablet) {
        font-size: 12px;
      }
      @media (max-width: $breakpoint-small) {
        font-size: 10px;
      }
    }

    & form {
      border: 1px solid var(--primary-purple-400);
      width: 50%;
      // background: var(--primary-grey);
      padding: 30px;
      display: flex;
      flex-direction: column;
      gap: 20px;

      @media (max-width: $breakpoint-large) {
        width: 80%;
        // margin: 0 var(--padding-main);
      }
      @media (max-width: $breakpoint-tablet) {
        width: 100%;
        padding: 15px;
      }

      & .top {
        // border: 2px solid blue;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;

        @media (max-width: $breakpoint-small) {
          // width: 100%;
          grid-template-columns: 1fr;
        }

        & .input-item {
          display: flex;
          flex-direction: column;
          gap: 10px;

          & label {
            font-size: 14px;

            @media (max-width: $breakpoint-tablet) {
              font-size: 12px;
            }
            @media (max-width: $breakpoint-small) {
              font-size: 10px;
            }
          }

          & input {
            padding: 10px;

            &:focus {
              outline: none;
            }
          }
        }
      }

      & .center {
        // border: 2px solid blue;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 20px;

        @media (max-width: $breakpoint-large) {
          display: flex;
          flex-wrap: wrap;
        }
        @media (max-width: $breakpoint-small) {
          display: grid;
          grid-template-columns: 1fr;
        }

        & .number {
          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
        }

        & .input-item {
          display: flex;
          flex-direction: column;
          gap: 10px;

          @media (max-width: $breakpoint-large) {
            width: 30%;
          }
          @media (max-width: $breakpoint-small) {
            width: auto;
          }

          & label {
            font-size: 14px;

            @media (max-width: $breakpoint-tablet) {
              font-size: 12px;
            }
            @media (max-width: $breakpoint-small) {
              font-size: 10px;
            }
          }

          & input,
          select {
            padding: 10px;

            &:focus {
              outline: none;
            }
          }
        }
      }

      & .message {
        display: flex;
        flex-direction: column;
        gap: 20px;
        & label {
          font-size: 14px;

          @media (max-width: $breakpoint-tablet) {
            font-size: 12px;
          }
          @media (max-width: $breakpoint-small) {
            font-size: 10px;
          }
        }

        & textarea {
          width: 100%;
          height: 100px;
          resize: vertical;
        }
      }

      & button {
        padding: 10px 20px;
        text-align: center;
        font-size: 15px;
        background: var(--primary-purple);
        border-radius: 5px;
        // width: 30%;
        margin: 0 auto;
        transition: var(--transition);
        border: 1px solid var(--primary-purple);
        color: white;
        cursor: pointer;

        @media (max-width: $breakpoint-tablet) {
          font-size: 12px;
        }
        @media (max-width: $breakpoint-small) {
          font-size: 10px;
          padding: 8px 20px;
        }

        &:hover {
          background: white;
          transition: var(--transition);
          color: var(--primary-blue);
        }
      }
    }
  }
}
