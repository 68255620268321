.individual {
  //   border: 2px solid red;
  min-height: 100vh;
  background: white;

  & .top {
    // border: 2px solid blue;
    height: 50vh;
    width: 100%;
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position-y: 20%;
    // object-fit: contain;
    z-index: 1;
    position: relative;
    display: flex;
    align-items: center;

    & .image {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;

      & img {
        object-fit: cover;
      }
    }

    & h1 {
      position: relative;
      z-index: 3;
      color: white;
      font-size: 35px;
      letter-spacing: 1px;
      padding: 0 var(--padding-main);
      transform: translateX(0);
      opacity: 1;
      transition: var(--transition-text);

      @media (max-width: $breakpoint-tablet) {
        padding: 0 var(--padding-tablet);
        font-size: 30px;
      }
      @media (max-width: $breakpoint-small) {
        font-size: 25px;
      }
    }
    &:before {
      content: "";
      position: absolute;
      z-index: 2;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      // background-color: rgba(0, 0, 0, 0.5);

      background: rgb(46, 54, 143);
      background: linear-gradient(
        90deg,
        rgba(46, 54, 143, 0.65) 0%,
        rgba(0, 0, 0, 0.65) 90%
      );
    }
  }

  & .translated {
    & h1 {
      transform: translateX(-50%);
      opacity: 0;
      transition: var(--transition-text);
    }
    // & p {
    //   transform: translateX(-50%);
    //   opacity: 0;
    //   transition: var(--transition-text-slow);
    // }
  }

  & .content {
    // border: 2px solid blue;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px var(--padding-main);
    gap: 40px;

    @media (max-width: $breakpoint-tablet) {
      padding: 50px var(--padding-tablet);
      gap: 30px;
    }
    @media (max-width: $breakpoint-small) {
      justify-content: center;
      align-items: center;
      gap: 25px;
    }

    & h2 {
      color: var(--primary-blue);
      font-size: 30px;
      letter-spacing: 1px;

      @media (max-width: $breakpoint-tablet) {
        font-size: 25px;
      }
      @media (max-width: $breakpoint-small) {
        font-size: 20px;
      }
    }

    & .packages {
      // border: 2px solid green;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-auto-rows: auto;
      gap: 40px;
      row-gap: 80px;

      @media (max-width: $breakpoint-large) {
        gap: 35px;
        grid-template-columns: 1fr 1fr;
      }
      @media (max-width: $breakpoint-tablet) {
        gap: 30px;
      }

      @media (max-width: $breakpoint-small) {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        width: 100%;
        gap: 20px;
      }

      & .item {
        border: 1px solid var(--primary-purple-400);
        display: flex;
        flex-direction: column;
        gap: 30px;
        padding: 30px;
        // box-shadow: 1px 2px 15px 1px rgb(168, 166, 166);
        border-radius: 10px;
        transition: var(--transition);

        @media (max-width: $breakpoint-small) {
          padding: 30px 20px;
          width: 90%;
        }

        & h2 {
          background: var(--primary-blue);
          padding: 10px;
          font-size: 21px;
          color: white;
          letter-spacing: 1px;

          @media (max-width: $breakpoint-tablet) {
            font-size: 19px;
          }
          @media (max-width: $breakpoint-small) {
            font-size: 17px;
          }
        }

        & h3 {
          font-size: 32px;
          padding-bottom: 20px;
          border-bottom: 1px solid var(--primary-blue);

          @media (max-width: $breakpoint-tablet) {
            font-size: 30px;
          }
          @media (max-width: $breakpoint-small) {
            font-size: 25px;
          }
        }

        & .text {
          display: flex;
          flex-direction: column;
          gap: 20px;

          @media (max-width: $breakpoint-tablet) {
            gap: 18px;
          }
          @media (max-width: $breakpoint-small) {
            gap: 16px;
          }

          & p {
            font-size: 14px;

            @media (max-width: $breakpoint-tablet) {
              font-size: 12px;
            }
            @media (max-width: $breakpoint-small) {
              font-size: 10px;
            }
          }

          & hr {
            border: none;
            height: 1px;
            width: 100%;
            background: rgb(199, 198, 198);
          }
        }

        &:hover {
          //   box-shadow: none;
          box-shadow: 1px 2px 5px 4px rgb(236, 234, 234);
          // border: none;

          transition: var(--transition);
        }
      }
    }
  }
}
