.booking {
  // border: 2px solid red;
  padding: 70px var(--padding-main);
  background: white;

  @media (max-width: $breakpoint-tablet) {
    padding: 70px var(--padding-tablet);
  }

  & h1 {
    font-size: 1.6rem;
    color: var(--primary-blue);
    letter-spacing: 2px;
    font-weight: 600;
    margin-bottom: 19px;
    width: 1012px;
    margin: 0 auto;
  }

  & h3 {
    font-size: 1.2rem;
    padding-bottom: 15px;
    border-bottom: 1px solid var(--primary-purple);
    width: fit-content;
    margin-bottom: 20px;
    width: 1012px;
    margin: 20px auto;

    @media (max-width: $breakpoint-large) {
      width: 100%;
    }
  }

  & .center {
    // border: 2px solid blue;
    margin: 0 auto;
    width: 1012px;
    height: 724px;
    display: grid;
    grid-template-columns: auto 1fr;

    @media (max-width: $breakpoint-large) {
      display: flex;
      justify-content: center;
      width: 100%;
      height: fit-content;
    }
    @media (max-width: $breakpoint-tablet) {
      border: 1px solid var(--primary-purple);
    }

    & .image {
      // position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      @media (max-width: $breakpoint-large) {
        display: none;
      }

      & img {
        object-fit: cover;
        height: 676px;
        width: 440px;
      }
    }

    & .form {
      // height: 100%;
      height: 676px;

      // width: 666px;
      // border: 2px solid green;
      padding: 30px;
      display: flex;
      flex-direction: column;
      // gap: 30px;
      justify-content: space-between;
      width: 100%;

      @media (max-width: $breakpoint-tablet) {
        min-height: 676px;
        height: fit-content;
        gap: 30px;
      }

      & .top {
        // border: 2px solid red;
        // display: flex;
        // justify-content: space-between;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;

        @media (max-width: $breakpoint-tablet) {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
        }

        & .item {
          display: flex;
          flex-direction: column;
          gap: 20px;
          min-width: 45%;

          @media (max-width: $breakpoint-small) {
            width: 100%;
          }

          & label {
            font-size: 14px;
            font-weight: 700;
          }

          & select {
            padding: 7px;

            & option {
              font-size: 0.8rem;
            }
          }

          & input {
            padding: 7px;
          }
        }
      }

      & .more {
        display: flex;
        gap: 10px;
        flex-direction: column;

        & label {
          font-size: 14px;
          font-weight: 700;
        }

        & textarea {
          padding: 7px;
          height: 45px;
          resize: none;
        }
      }

      & .slot {
        // border: 2px solid red;
        display: flex;
        flex-direction: column;
        font-size: 14px;
        gap: 10px;

        @media (max-width: $breakpoint-small) {
          gap: 15px;
        }

        & h2 {
          font-size: 14px;
        }

        & .items {
          //   border: 2px solid purple;
          display: grid;
          grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
          gap: 10px;

          @media (max-width: $breakpoint-small) {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
          }
          @media (max-width: $breakpoint-mobile) {
            gap: 25px;
            // justify-content: space-between;
          }

          & .item {
            padding: 10px;
            border: 1px solid var(--primary-purple);
            text-align: center;
            display: flex;
            flex-direction: column;
            gap: 10px;
            cursor: pointer;
            transition: var(--transition);

            &:hover {
              color: white;
              background: var(--primary-purple);
              transition: var(--transition);
            }
          }
          & .active {
            color: white;
            background: var(--primary-purple);
          }
        }
      }

      & .name {
        // border: 2px solid red;
        display: grid;
        grid-template-columns: 20% 1fr 1fr;
        gap: 15px;

        @media (max-width: $breakpoint-tablet) {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
        }
        @media (max-width: $breakpoint-small) {
          display: grid;
          grid-template-columns: none;
          grid-auto-flow: row;
          justify-content: unset;
        }

        & input,
        select {
          padding: 7px;
        }
      }

      & .details {
        // border: 2px solid red;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 30px;

        @media (max-width: $breakpoint-tablet) {
          display: flex;
          flex-wrap: wrap;
        }

        & input,
        select {
          padding: 7px;

          @media (max-width: $breakpoint-small) {
            width: 100%;
          }
        }

        & .number {
          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
        }
      }

      & button {
        padding: 16px 0;
        text-align: center;
        border: none;
        border-radius: 5px;
        // color: var(--primary-purple);
        font-size: 15px;
        font-weight: 400;
        letter-spacing: 1px;
        cursor: pointer;
        transition: var(--transition);
        border: 1px solid var(--primary-purple);
        background: var(--primary-purple);
        color: white;

        &:hover {
          background: white;
          color: black;
          // transform: scale(0.98);
          transition: var(--transition);
        }
      }
    }
  }
}

.home-service {
  height: 100vh;
  background-repeat: no-repeat;
  //   object-fit: contain;
  background-size: 100% 100%;
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  &:before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 2;
    background: rgba(0, 0, 0, 0.4);
    // background: rgba(46, 54, 143, 0.4);
  }

  & .form {
    position: relative;
    z-index: 9999;
    color: white;
    // border: 2px solid red;
    width: 50%;
    // height: 80%;
    display: flex;
    flex-direction: column;
    gap: 40px;
    // background: rgba(0, 0, 0, 0.3);
    background: rgba(46, 54, 143, 0.3);

    border-radius: 10px;
    padding: 40px;

    @media (max-width: $breakpoint-large) {
      min-width: 50%;
      width: fit-content;
    }

    & h1 {
      text-align: center;
      font-size: 25px;
      // color: var(--primary-blue);
      letter-spacing: 1px;

      @media (max-width: $breakpoint-large) {
        font-size: 22px;
      }
      @media (max-width: $breakpoint-tablet) {
        font-size: 20px;
      }
      @media (max-width: $breakpoint-small) {
        font-size: 18px;
      }
    }

    & .center {
      //   border: 2px solid blue;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;

      @media (max-width: $breakpoint-mobile) {
        grid-template-columns: 1fr;
      }

      & .item {
        display: flex;
        flex-direction: column;
        gap: 20px;

        & label {
          color: rgb(148, 146, 146);

          @media (max-width: $breakpoint-large) {
            font-size: 17px;
          }
          @media (max-width: $breakpoint-tablet) {
            font-size: 15px;
          }
          @media (max-width: $breakpoint-small) {
            font-size: 13px;
          }
        }

        & input {
          padding: 10px;
          border-radius: 5px;
          border: none;

          @media (max-width: $breakpoint-tablet) {
            padding: 8px;
            font-size: 15px;
          }
          @media (max-width: $breakpoint-small) {
            font-size: 13px;
            padding: 8px;
          }
        }

        & .number {
          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
        }
      }
    }
    & button {
      width: 40%;
      padding: 15px 20px;
      border: 1px solid var(--primary-purple);
      margin: 0 auto;
      border-radius: 10px;
      cursor: pointer;
      transition: var(--transition);

      font-size: 14px;
      font-weight: 400;
      letter-spacing: 1px;
      text-transform: uppercase;
      background: var(--primary-purple);
      color: white;

      @media (max-width: $breakpoint-tablet) {
        padding: 12px 20px;
        border-radius: 5px;
        font-size: 12px;
      }
      @media (max-width: $breakpoint-small) {
        font-size: 11px;
        padding: 8px;
      }

      &:hover {
        transition: var(--transition);
        background: white;
        color: black;
      }
    }
  }
}
