.patners {
  padding: var(--padding-main);
  color: black;
  // background: var(--primary-blue);
  background: white;
  display: flex;
  height: 500px;
  gap: 30px;
  align-items: center;

  @media (max-width: $breakpoint-tablet) {
    padding: var(--padding-tablet);
  }
  @media (max-width: $breakpoint-small) {
    flex-wrap: wrap;
    gap: 20px;
  }

  & .left {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 40px;

    @media (max-width: $breakpoint-small) {
      width: 100%;
    }

    & h2 {
      font-size: 30px;
      text-transform: capitalize;
      text-align: right;
      color: var(--primary-blue);

      @media (max-width: $breakpoint-tablet) {
        font-size: 25px;
      }
      @media (max-width: $breakpoint-small) {
        font-size: 20px;
        text-align: center;
      }
    }

    & p {
      font-size: 18px;
      text-align: justify;
      line-height: 30px;

      @media (max-width: $breakpoint-tablet) {
        font-size: 15px;
      }
      @media (max-width: $breakpoint-small) {
        font-size: 11px;
        text-align: center;
      }
    }
  }

  & .right {
    width: 50%;
    height: 100px;
    display: flex;
    align-items: center;

    @media (max-width: $breakpoint-small) {
      margin: 0 auto;
    }

    & .images {
      display: flex;
      gap: 50px;
      align-items: center;
      justify-content: center;

      & img {
        grid-template-columns: 1fr 1fr;
        width: 100%;
        object-fit: contain;
      }
    }
  }
}
