@import "./modules/variables";
@import "./modules/navbar";
@import "./modules/sectionOne";
@import "./modules/about";
@import "./modules/team";
@import "./modules/aboutHome.scss";
@import "./modules/servicesHome.scss";
@import "./modules/footer";
@import "./modules/services";
@import "./modules/sectionFour";
@import "./modules/patients";
@import "./modules/contact";
@import "./modules/corporate";
@import "./modules/individual";
@import "./modules/feedback";
@import "./modules/error";
@import "./modules/faq";
@import "./modules/greeting";
@import "./modules/newsletter";
@import "./modules/patners";

body {
  background: var(--primary-grey);
  color: black;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 6px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: var(--primary-blue);
    border-radius: 100px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: grey;
  }
}
