.services {
  // border: 2px solid red;
  display: flex;
  flex-direction: column;
  // gap: 40px;
  padding-top: 80px;
  padding-bottom: 80px;
  //   width: 100%;
  justify-content: center;
  align-items: center;
  background: white;
  // height: 120vh;

  & h1 {
    color: var(--primary-blue);
    font-size: 35px;
  }

  & .center {
    // border: 2px solid blue;
    // height: 70%;
    display: grid;
    grid-template-columns: auto auto;
    grid-auto-rows: auto;
    justify-content: center;
    align-items: center;
    gap: 30px;
    width: 100%;
    background: white;
    padding: 50px var(--padding-main);
    border-radius: 5px;

    @media (max-width: $breakpoint-tablet) {
      // padding: 50px var(--padding-tablet);
      display: flex;
      flex-wrap: wrap;
    }

    & .item-center {
      // border: 2px solid red;
      transition: var(--transition-slow);
      // height: 80px;
      height: fit-content;
      // width: 500px;
      height: 350px;
      width: 580px;
      background-size: cover;
      position: relative;

      & .item {
        transition: var(--transition-slow);
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 12px;
        padding: 25px;
        border-radius: 15px;
        border: 1px solid rgba(203, 108, 230, 0.4);
        align-items: center;
        justify-content: center;
        z-index: 3;
        // border: 2px solid red;

        @media (max-width: $breakpoint-small) {
          width: 300px;
          height: fit-content;
        }

        & h2 {
          font-size: 49px;
          letter-spacing: 2px;
          text-transform: uppercase;
          color: white;
          font-weight: 700;
          font-family: Baumans;
          display: flex;
          align-items: center;
          gap: 1px;
          z-index: 3;
          text-align: center;

          @media (max-width: $breakpoint-tablet) {
            font-size: 17px;
          }

          & span {
            display: flex;
            align-items: center;
            color: white;
            display: none;
          }
        }

        & ul {
          text-decoration: none;
          list-style-type: none;
          display: none;
          transition: var(--transition-slow);
          // display: flex;
          flex-direction: column;
          gap: 13px;
          z-index: 3;
          align-items: center;
          justify-content: center;

          & li {
            font-size: 30px;
            color: white;
            width: fit-content;
            // border: 2px solid red;

            @media (max-width: $breakpoint-tablet) {
              font-size: 17px;
            }
            @media (max-width: $breakpoint-small) {
              font-size: 15px;
            }

            & .read-more {
              text-decoration: none;
              text-transform: uppercase;
              border-radius: 5px;
              font-size: 20px;
              cursor: pointer;
              transition: var(--transition);
              width: fit-content;
              display: flex;
              align-items: center;
              gap: 3px;
              color: var(--primary-purple);

              @media (max-width: $breakpoint-tablet) {
                font-size: 13px;
              }
            }

            & .read-more:hover {
              transition: var(--transition);
              color: var(--primary-grey);
            }
          }
        }
      }

      &:before {
        position: absolute;
        content: "";
        height: 100%;
        width: 100%;
        background: rgba(46, 54, 143, 0.6);
        transition: var(--transition);
      }

      &:hover {
        // filter: brightness(50%);
        &:after {
          position: absolute;
          content: "";
          height: 100%;
          width: 100%;
          background: rgba(0, 0, 0, 0.5);
          transition: var(--transition);
          z-index: 2;
          top: 0;
          left: 0;
        }

        &:before {
          background: rgba(46, 54, 143, 0.9);
          transition: var(--transition);
          // z-index: 3;
        }

        & .item {
          transition: var(--transition);
          // height: 150px;

          & h2 {
            display: none;
          }

          & ul {
            transition: var(--transition-slow);
            text-decoration: none;
            list-style-type: none;
            display: flex;
            flex-direction: column;
            opacity: 1;
            transform: translateY(0);
          }
        }
      }
    }
  }
}
