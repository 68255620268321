.services-main {
  //   border: 2px solid red;
  // padding: 70px var(--padding-main);
  display: flex;
  flex-direction: column;
  gap: 60px;
  background: white;

  & .top {
    // border: 2px solid red;
    height: 60vh;
    width: 100%;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position-y: 50%;
    color: white;
    position: relative;
    // z-index: 1;
    padding: 40px var(--padding-main);
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 30px;

    @media (max-width: $breakpoint-tablet) {
      padding: 40px var(--padding-tablet);
    }

    &::before {
      content: "";
      position: absolute;
      height: 100%;
      width: 100%;
      z-index: 2;
      // background: rgba(0, 0, 0, 0.4);
      top: 0;
      left: 0;
      background: rgb(46, 54, 143);
      background: linear-gradient(
        90deg,
        rgba(46, 54, 143, 0.6) 0%,
        rgba(0, 0, 0, 0.7) 100%
      );
    }
    & .image {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;

      & img {
        object-fit: cover;
      }
    }

    & h2 {
      position: relative;
      z-index: 99999;
      font-size: 40px;
      width: 50%;
      transform: translateX(0);
      opacity: 1;
      transition: var(--transition-text);

      @media (max-width: $breakpoint-large) {
        width: 70%;
        font-size: 37px;
      }
      @media (max-width: $breakpoint-tablet) {
        width: 90%;
        font-size: 35px;
      }
      @media (max-width: $breakpoint-small) {
        width: 90%;
        font-size: 32px;
      }
      @media (max-width: $breakpoint-mobile) {
        width: 90%;
        font-size: 30px;
      }
    }

    & p {
      z-index: 99999;
      position: relative;
      font-size: 18px;
      display: flex;
      align-items: center;
      gap: 6px;
      transform: translateX(0);
      opacity: 1;
      transition: var(--transition-text-slow);

      @media (max-width: $breakpoint-large) {
        font-size: 16px;
      }
      @media (max-width: $breakpoint-tablet) {
        font-size: 15px;
      }
      @media (max-width: $breakpoint-small) {
        font-size: 12px;
      }
      @media (max-width: $breakpoint-mobile) {
        font-size: 12px;
      }
    }
  }

  & .translated {
    & h2 {
      transform: translateY(70%);
      opacity: 0;
      transition: var(--transition-text);
    }
    & p {
      transform: translateY(85%);
      opacity: 0;
      transition: var(--transition-text-slow);
    }
  }

  & .services-center {
    padding: 40px var(--padding-main);
    // border: 2px solid red;

    @media (max-width: $breakpoint-tablet) {
      padding: 40px var(--padding-tablet);
    }
  }
  & h1 {
    font-size: 35px;
    letter-spacing: 2px;
    text-transform: capitalize;
    font-weight: 700;
    margin-bottom: 30px;

    @media (max-width: $breakpoint-large) {
      font-size: 32px;
    }
    @media (max-width: $breakpoint-tablet) {
      font-size: 20px;
    }
    @media (max-width: $breakpoint-small) {
      font-size: 28px;
    }
    @media (max-width: $breakpoint-mobile) {
      font-size: 26px;
    }
  }

  & h3 {
    color: var(--primary-blue);
    font-size: 20px;
    font-weight: 500;
    // border-bottom: 1px solid var(--primary-purple);
    padding-bottom: 15px;
    width: fit-content;

    @media (max-width: $breakpoint-large) {
      font-size: 18px;
    }
    @media (max-width: $breakpoint-tablet) {
      font-size: 16px;
    }
    @media (max-width: $breakpoint-small) {
      font-size: 15px;
    }
    @media (max-width: $breakpoint-mobile) {
      font-size: 14px;
    }
  }

  & .service {
    // border: 2px solid blue;
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-bottom: 40px;

    & h1 {
      background: var(--primary-blue);
      color: white;
      padding: 10px 15px;
      letter-spacing: 3px;
      border-radius: 5px;

      @media (max-width: $breakpoint-large) {
        font-size: 18px;
      }
      @media (max-width: $breakpoint-tablet) {
        font-size: 16px;
      }
      @media (max-width: $breakpoint-small) {
        font-size: 15px;
      }
      @media (max-width: $breakpoint-mobile) {
        font-size: 14px;
      }
    }

    & .center {
      //   border: 2px solid green;
      display: grid;
      // grid-template-columns: 1fr 1fr 1fr;
      grid-template-columns: repeat(6, 1fr);
      gap: 30px;
      row-gap: 100px;
      grid-auto-rows: auto;
      //   position: relative;
      // align-items: center;
      // justify-content: center;

      @media (max-width: $breakpoint-large) {
        display: flex;
        flex-wrap: wrap;
        gap: 25px;
      }
      @media (max-width: $breakpoint-large) {
        justify-content: center;
        gap: 20px;
      }

      & .item {
        // border: 2px solid red;
        box-shadow: 1px 1px 5px 2px rgb(224, 223, 223);
        // height: 150px;
        // width: 150px;
        display: flex;
        flex-direction: column;
        // gap: 25px;
        grid-column: span 2;
        position: relative;
        max-width: 350px;
        transition: var(--transition);

        @media (max-width: $breakpoint-large) {
          width: 300px;
        }
        @media (max-width: $breakpoint-tablet) {
          width: 350px;
        }
        @media (max-width: $breakpoint-mobile) {
          width: 300px;
        }

        & .image {
          position: relative;
          // border: 2px solid red;
          height: 210px;
          max-width: 400px;
          transition: var(--transition);

          &:after {
            content: "";
            position: absolute;
            z-index: 999;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.5);
          }
        }

        & img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }

        & h4 {
          font-weight: 700;
          font-size: 16px;
          text-align: center;
          padding: 30px;
          transition: var(--transition);
          opacity: 1;

          @media (max-width: $breakpoint-large) {
            padding: 25px;
            font-size: 15px;
          }
          @media (max-width: $breakpoint-tablet) {
            padding: 22px;
            font-size: 14px;
          }
          @media (max-width: $breakpoint-small) {
            padding: 20px;
            font-size: 13px;
          }
          @media (max-width: $breakpoint-mobile) {
            padding: 18px;
            font-size: 12px;
          }
        }
        & p {
          // font-weight: 700;
          font-size: 16px;
          letter-spacing: 1px;
          text-align: center;
          line-height: 23px;
          padding: 20px;
          position: absolute;
          transition: var(--transition);
          opacity: 0;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          @media (max-width: $breakpoint-large) {
            font-size: 15px;
          }
          @media (max-width: $breakpoint-tablet) {
            line-height: 22px;
            font-size: 14px;
          }
          @media (max-width: $breakpoint-small) {
            line-height: 24px;
            font-size: 13px;
          }
          @media (max-width: $breakpoint-mobile) {
            font-size: 12px;
          }
        }

        /* Dealing with 2 orphan items */
        &:last-child:nth-child(3n - 1) {
          grid-column-end: -2;
        }

        &:nth-last-child(2):nth-child(3n + 1) {
          grid-column-end: 4;
        }

        /* Dealing with single orphan */
        // &:last-child:nth-child(3n - 2) {
        //   grid-column-end: 5;
        // }

        &:hover {
          & .image,
          h4 {
            opacity: 0;
            transition: var(--transition);
          }
          & p {
            opacity: 1;
            transition: var(--transition);
          }
        }
      }
    }
  }
}
