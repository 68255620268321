.about-home {
  // border: 2px solid red;
  // height: 110vh;
  align-items: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: rgb(226, 221, 228);

  padding: 100px var(--padding-main) 25px;

  @media (max-width: $breakpoint-tablet) {
    padding: 50px var(--padding-tablet);
  }
  @media (max-width: $breakpoint-small) {
    padding: 50px var(--padding-small);
  }
  & .group {
    box-shadow: 1px 1px 6px 1px rgb(209, 208, 208);
    height: 450px;
    position: relative;
    width: 650px;
    margin-left: 200px;

    @media (max-width: $breakpoint-tablet) {
      margin-left: 0px;
      box-shadow: none;
      display: flex;
      justify-content: center;
    }
    @media (max-width: $breakpoint-small) {
      height: fit-content;
      width: fit-content;
    }
  }

  & h2 {
    text-align: left;
    // color: var(--primary-purple);
    color: var(--primary-blue);
    font-size: 28px;
    // letter-spacing: 1px;

    @media (max-width: $breakpoint-tablet) {
      font-size: 22px;
    }
  }

  & .left {
    padding: 30px;
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.95);
    width: 400px;
    max-width: 400px;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    position: absolute;
    z-index: 999;
    top: -10%;
    left: -200px;
    padding: 3rem 1.5rem;

    @media (max-width: $breakpoint-tablet) {
      position: static;
    }
    @media (max-width: $breakpoint-small) {
      padding: 15px;
      gap: 15px;
      width: 300px;
      height: 80%;
    }

    & h4 {
      text-align: left;
      font-weight: 100;
      font-size: 20px;
      border-bottom: 1px solid var(--primary-purple);
      padding: 5px;
      opacity: 0.6;

      @media (max-width: $breakpoint-small) {
        font-size: 16px;
      }
    }

    & p {
      font-size: 15px;
      opacity: 0.8;
      // letter-spacing: 1px;
      line-height: 25px;
      text-align: left;

      @media (max-width: $breakpoint-tablet) {
        font-size: 12px;
      }
    }

    & .read-more {
      width: fit-content;
      text-decoration: none;

      & button {
        border: 1px solid var(--primary-purple);
        padding: 10px 20px;
        color: white;
        background: var(--primary-purple);
        border-radius: 5px;
        cursor: pointer;
        display: flex;
        align-items: center;
        transition: var(--transition-slow);
        gap: 5px;

        @media (max-width: $breakpoint-tablet) {
          padding: 7px 16px;
        }
      }
      & button:hover {
        transition: var(--transition-slow);
        background: white;
        color: black;
      }
    }
  }

  & .right {
    // border: 2px solid red;
    width: 100%;
    height: 100%;
    max-width: 650px;

    @media (max-width: $breakpoint-tablet) {
      display: none;
    }

    & .image {
      height: 100%;
      width: 100%;
    }
  }
  & img {
    height: 100%;
    width: 100%;
  }
}
