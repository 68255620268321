.faq-main {
  // border: 2px solid red;

  & .faq-form {
    padding: 60px var(--padding-main) 100px;
    // border: 2px solid red;
    background: rgb(219, 227, 241);
  }
}

.faqtop {
  height: 495px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  flex-direction: column;

  &:before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgba(46, 54, 143, 0.35);
    top: 0;
    left: 0;
  }

  & h1,
  .input {
    z-index: 3;
  }

  & h1 {
    color: white;
    font-weight: 600;
    font-size: 35px;

    @media (max-width: $breakpoint-tablet) {
      font-size: 30px;
    }
    @media (max-width: $breakpoint-small) {
      font-size: 25px;
    }
  }

  & .input {
    width: 50%;
    height: 52px;
    position: relative;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;

    & input {
      padding: 20px;
      height: 100%;
      width: 100%;
      border-radius: 5px;

      border: none;
      font-size: 20px;

      @media (max-width: $breakpoint-tablet) {
        padding: 18px;
        font-size: 18px;
      }
      @media (max-width: $breakpoint-small) {
        padding: 15px;
        font-size: 15px;
      }

      &:focus {
        border-bottom-right-radius: 0px;
        border-bottom-left-radius: 0px;
      }
    }

    & span {
      position: absolute;
      right: 10px;
      top: 50%;
      height: 30px;
      width: 30px;
      margin-top: -15px;
      color: var(--primary-blue);
      font-size: 25px;

      @media (max-width: $breakpoint-tablet) {
        font-size: 22px;
        margin-top: -10px;
      }
      @media (max-width: $breakpoint-small) {
        font-size: 18px;
        margin-top: -9px;
      }
    }

    & .questions {
      background: white;
      display: flex;
      flex-direction: column;
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px;
      max-height: 180px;
      overflow-y: scroll;

      & p {
        padding: 20px;
        height: 60px;
        font-size: 15px;
        border-bottom: 1px solid var(--primary-grey);
        cursor: pointer;
        transition: var(--transition);
        text-transform: capitalize;

        @media (max-width: $breakpoint-tablet) {
          font-size: 13px;
        }
        @media (max-width: $breakpoint-small) {
          font-size: 11px;
        }

        &:hover {
          color: white;
          background: var(--primary-blue);
          transition: var(--transition);
        }
      }
    }
  }
}
