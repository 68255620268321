.team {
  background: white;
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 80px var(--padding-main);

  & h2 {
    text-align: center;
    color: var(--primary-blue);
    font-size: 40px;
    letter-spacing: 1px;

    @media (max-width: $breakpoint-tablet) {
      font-size: 35px;
    }
    @media (max-width: $breakpoint-small) {
      font-size: 30px;
    }
  }

  & .people {
    display: grid;
    grid-template-columns: 1fr 1fr;
    // display: flex;
    // flex-wrap: wrap;
    justify-content: center;
    width: fit-content;
    gap: 180px;
    align-items: center;
    // border: 2px solid blue;
    row-gap: 60px;
    margin-left: -30px;
    max-width: 900px;
    margin: 0 auto;

    @media (max-width: $breakpoint-tablet) {
      display: flex;
      flex-wrap: wrap;
    }
  }

  & .person {
    // border: 2px solid green;
    // padding: 30px 20px;
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 20px;
    border-radius: 10px;
    box-shadow: 1px 2px 12px 1px rgb(231, 228, 228);
    width: 290px;
    height: 240px;
    background: linear-gradient(180deg, var(--primary-grey) 0%, white 70%);
    transition: var(--transition);

    // width: 30%;
    max-width: 350px;
    position: relative;

    & .image {
      height: 100%;
      // width: 200px;
      width: 100%;
      border-radius: 10px;
      // border-radius: 50%;
      background-size: cover;
      background-position: top;
      object-fit: cover;
      background-repeat: no-repeat;
    }

    & .info {
      position: absolute;
      top: 50%;
      right: -130px;
      width: 180px;
      height: 140px;
      // border: 2px solid red;
      padding: 20px;
      display: flex;
      flex-direction: column;
      gap: 15px;
      margin-top: -70px;
      transition: var(--transition);

      & h3 {
        font-size: 1rem;
        // text-align: center;
        text-transform: uppercase;

        @media (max-width: $breakpoint-tablet) {
          font-size: 0.8rem;
        }
        @media (max-width: $breakpoint-small) {
          font-size: 0.65rem;
        }
      }

      & span {
        width: 50px;
        height: 3px;
        border-radius: 3px;
        border: none;
        background: var(--primary-blue);
      }

      & p {
        font-size: 0.7rem;
        // text-align: center;

        @media (max-width: $breakpoint-tablet) {
          font-size: 0.6rem;
        }
        @media (max-width: $breakpoint-small) {
          font-size: 0.5rem;
        }
      }

      & .role {
        color: var(--primary-purple);
        text-transform: capitalize;
      }
    }

    // & .company {
    //   color: var(--primary-purple);
    //   text-transform: capitalize;
    // }

    &:hover {
      box-shadow: 1px 2px 15px 1px rgb(239, 238, 238);
      transition: var(--transition);

      & .info {
        // border: 2px solid red;
        box-shadow: 2px 1px 12px 1px rgb(238, 236, 236);
        background: white;
        border-radius: 5px;
        transition: var(--transition);
      }
    }
  }

  & .bottom {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;

    & .person {
      width: 32%;
      min-width: 250px;

      width: 30%;
      max-width: 450px;
    }
  }
}
