.section-one {
  position: relative;
  // border: 2px solid red;
  // margin-top: 20px;
  height: 100vh;
  // max-height: 500px;
  // background-repeat: no-repeat;
  // background-size: 100% 100%;
  // background-size: contain;
  // z-index: 1;
  display: flex;
  align-items: center;
  position: relative;
  //   back
  width: 100vw;
  overflow: hidden;

  & .section-content {
    // min-width: 100%;
    width: 100vw;
    display: flex;
    // transform: translateX(-300%);
    transition: var(--transition-move);
    z-index: 2;

    & .main {
      padding-left: var(--padding-main);
      position: relative;
      min-width: 100%;
      display: flex;
      align-items: center;
      height: 100vh;
      // z-index: 6;

      &::before {
        content: "";
        position: absolute;
        z-index: 3;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        // background-color: rgba(0, 0, 0, 0.5);

        background: rgb(46, 54, 143);
        background: linear-gradient(
          90deg,
          rgba(46, 54, 143, 0.65) 5%,
          rgba(0, 0, 0, 0.65) 100%
        );
      }

      @media (max-width: $breakpoint-tablet) {
        padding-left: var(--padding-tablet);
      }
    }
  }

  & .image {
    position: absolute;
    height: 100%;
    width: 100vw;
    top: 0;
    left: 0;

    & img {
      object-fit: cover;

      @media (max-width: $breakpoint-large) {
        object-fit: cover;
      }
      @media (max-width: $breakpoint-tablet) {
        object-fit: cover;
      }
    }
  }

  & .text {
    position: relative;
    // border: 2px solid blue;
    color: white;
    // color: var(--primary-purple);
    z-index: 6;
    width: 53%;

    display: flex;
    flex-direction: column;
    gap: 40px;

    @media (max-width: $breakpoint-small) {
      width: 90%;
    }

    & h3 {
      font-size: 40px;
      text-transform: capitalize;
      letter-spacing: 2px;
      line-height: 60px;
      transform: translateX(0);
      opacity: 1;
      transition: var(--transition-text);
      // z-index: 5;
      position: relative;
      @media (max-width: $breakpoint-large) {
        font-size: 40px;
      }
      @media (max-width: $breakpoint-tablet) {
        line-height: 35px;
        font-size: 20px;
      }
    }

    & p {
      font-size: 23px;
      transform: translateX(0);
      opacity: 1;
      transition: var(--transition-text-slow);

      @media (max-width: $breakpoint-large) {
        font-size: 20px;
      }
      @media (max-width: $breakpoint-tablet) {
        font-size: 15px;
      }
    }
  }

  & .translated {
    & h3 {
      transform: translateY(70%);
      opacity: 0;
      transition: var(--transition-text);
    }
    & p {
      transform: translateY(85%);
      opacity: 0;
      transition: var(--transition-text-slow);
    }
  }

  & .left-button {
    position: absolute;
    top: calc(50% + 10px);
    // top: 460px;
    left: 57px;

    z-index: 3;
    cursor: pointer;

    @media (max-width: $breakpoint-large) {
      top: 302px;
      left: 40px;
      height: 565px;
    }
    @media (max-width: $breakpoint-tablet) {
      & img {
        height: 20px;
        width: 20px;
      }
    }
  }

  & .right-button {
    position: absolute;
    // top: 460px;
    top: calc(50% + 10px);
    // top: 50%;
    right: 57px;

    z-index: 3;
    cursor: pointer;

    @media (max-width: $breakpoint-large) {
      top: 302px;
      right: 40px;
    }

    @media (max-width: $breakpoint-tablet) {
      & img {
        height: 20px;
        width: 20px;
      }
    }
  }

  & .sections {
    position: absolute;
    bottom: 100px;
    left: 50%;
    width: 100px;
    transform: translateX(-50px);
    z-index: 3;
    display: flex;
    gap: 10px;

    & .section {
      height: 10px;
      width: 10px;
      background: white;
      border-radius: 10px;
    }

    & .active {
      background: var(--primary-blue);
    }
  }
}

.section-one::before {
}
