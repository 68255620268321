footer {
  // height: 60vh;
  background: var(--primary-purple);
  // background: rgb(98, 102, 105);
  color: white;

  display: flex;
  flex-direction: column;
  gap: 50px;
  padding: 100px 0 50px;
  // height:

  @media (max-width: $breakpoint-tablet) {
    padding: 100px var(--padding-tablet) 50px;
  }

  & .content {
    // border: 2px solid red;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0px var(--padding-main);
    // gap: 80px;
    justify-content: space-between;

    @media (max-width: $breakpoint-large) {
      gap: 50px;
    }
    @media (max-width: $breakpoint-tablet) {
      width: fit-content;
      flex-wrap: wrap;
      // padding: 0px var(--padding-tablet);
      padding: 0;
      // justify-content: flex-start;
      gap: 50px;
      column-gap: 40px;
    }
  }

  & .top {
    // border: 2px solid red;
    // border-bottom: 1px solid white;
    display: flex;
    flex-direction: column;
    gap: 20px;
    // padding: 0px var(--padding-main);
    // padding-bottom: 20px;

    & img {
      width: 160px;
      height: 60px;
    }

    & .text {
      display: flex;
      gap: 10px;

      & span {
        // color: var(--primary-purple);
        color: white;
        font-size: 18px;

        @media (max-width: $breakpoint-tablet) {
          font-size: 16px;
        }
        @media (max-width: $breakpoint-small) {
          font-size: 14px;
        }
      }

      & p {
        font-size: 14px;

        @media (max-width: $breakpoint-tablet) {
          font-size: 12px;
        }
        @media (max-width: $breakpoint-small) {
          font-size: 10px;
        }
      }
    }
  }

  & hr {
    height: 1px;
    border: none;
    background: rgba(255, 255, 255, 0.4);
  }

  // & .bottom {
  //   // border: 2px solid red;
  //   width: 100%;
  //   display: flex;
  //   flex-direction: row;
  //   // gap: 30px;
  //   justify-content: space-between;
  //   // padding: 0px var(--padding-main);

  & .item {
    // border: 2px solid green;
    display: flex;
    flex-direction: column;
    gap: 15px;

    @media (max-width: $breakpoint-tablet) {
      // justify-content: center;
      // align-items: center;
      min-width: 40%;
    }

    & h1 {
      font-size: 15px;
      text-transform: uppercase;
      letter-spacing: 1px;
      color: rgba(255, 255, 255, 0.75);

      @media (max-width: $breakpoint-tablet) {
        font-size: 13px;
      }
      @media (max-width: $breakpoint-small) {
        font-size: 11px;
      }
    }

    & p {
      font-size: 14px;
      display: flex;
      gap: 10px;

      @media (max-width: $breakpoint-tablet) {
        font-size: 12px;
      }
      @media (max-width: $breakpoint-small) {
        font-size: 10px;
      }

      & .links {
        text-decoration: none;
        color: white;
      }
    }

    & .icons {
      display: flex;
      // justify-content: space-between;
      gap: 20px;
      font-size: 20px;

      & p {
        display: flex;
        gap: 10px;
      }

      & a {
        color: white;
        cursor: pointer;
      }
    }
  }
  // }

  & .copyright {
    padding: 0px var(--padding-main);
    text-transform: unset;
    color: rgba(255, 255, 255, 0.6);
    font-size: 12px;
    letter-spacing: 1px;

    @media (max-width: $breakpoint-tablet) {
      font-size: 11px;
    }
    @media (max-width: $breakpoint-small) {
      font-size: 10px;
    }

    @media (max-width: $breakpoint-tablet) {
      padding: 0;
    }
    // @media (max-width: $breakpoint-small) {
    //   padding: 0px var(--padding-small);
    // }
  }
}
