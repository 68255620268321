.greeting {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(128, 128, 128, 0.7);
  z-index: 9999999999;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & .greeting-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transform: scale(0.75, 0.75);

    @media (max-width: $breakpoint-mobile) {
      transform: scale(0.6, 0.6);
    }
    @media (max-width: $breakpoint-small) {
      transform: scale(0.5, 0.5);
    }
  }

  & .group {
    padding: 20px;
    background: white;
    width: 850px;
    // grid-template-columns: 1fr 1fr÷;

    @media (max-width: $breakpoint-tablet) {
      width: 570px;
      padding: 15px;
    }
    @media (max-width: $breakpoint-small) {
      padding: 10px;
      width: 370px;
    }
    @media (max-width: $breakpoint-mobile) {
      padding: 8px;
      width: 340px;
    }

    & .group-center {
      border: 2px solid var(--primary-purple);
      display: flex;
    }

    & .left {
      width: 50%;
      padding: 30px;
      display: flex;
      gap: 20px;
      flex-direction: column;

      @media (max-width: $breakpoint-tablet) {
        padding: 20px;
        font-size: 20px;
      }
      @media (max-width: $breakpoint-small) {
        padding: 10px;
        font-size: 17px;
      }

      & h1 {
        font-size: 25px;
        color: var(--primary-blue);

        @media (max-width: $breakpoint-tablet) {
          font-size: 20px;
        }
        @media (max-width: $breakpoint-small) {
          font-size: 17px;
        }
      }

      & p {
        font-size: 15px;
        line-height: 30px;
        text-align: justify;

        @media (max-width: $breakpoint-tablet) {
          line-height: 23px;
          font-size: 13px;
        }
        @media (max-width: $breakpoint-small) {
          font-size: 11px;
          line-height: 15px;
        }
      }
    }

    & .right {
      width: 50%;
      position: relative;

      & span {
        position: absolute;
        top: 10px;
        right: 10px;
        font-size: 30px;
        cursor: pointer;
        color: var(--primary-blue);
      }
      & img {
        height: 460px;
        width: 100%;

        @media (max-width: $breakpoint-tablet) {
          height: 400px;
        }
        @media (max-width: $breakpoint-small) {
          height: 300px;
        }
      }
    }
  }

  & .group-one {
    transform: translateY(-120px);
  }

  & .group-two {
    // border: 2px solid red;
    position: absolute;
    bottom: -120px;
    transform: translateX(110px);
    box-shadow: 1px 1px 13px 5px rgba(129, 127, 127, 0.5);
    width: 850px;
    height: 330px;
    @media (max-width: $breakpoint-tablet) {
      width: 570px;
      transform: translateX(50px);
    }
    @media (max-width: $breakpoint-small) {
      width: 370px;
      height: 220px;
      bottom: -60px;
    }

    @media (max-width: $breakpoint-mobile) {
      width: 340px;
      transform: translateX(20px);
    }

    & .group-center {
      border: none;

      & .left {
        justify-content: space-between;
        gap: 0;
        padding-bottom: 10px;
      }
    }

    & .right {
      & img {
        height: 300px;
        // height: 100%;

        @media (max-width: $breakpoint-tablet) {
          height: 280px;
        }
        @media (max-width: $breakpoint-small) {
          height: 200px;
        }
      }
    }

    & button {
      background: white;
      border: 1px solid var(--primary-purple);
      padding: 20px;
      border-radius: 10px;
      text-transform: capitalize;
      font-size: 20px;
      cursor: pointer;
      transition: var(--transition);
      background: var(--primary-purple);
      color: white;

      @media (max-width: $breakpoint-tablet) {
        font-size: 17px;
        padding: 17px;
      }
      @media (max-width: $breakpoint-small) {
        font-size: 13px;
        padding: 13px;
      }

      &:hover {
        color: black;
        background: white;
        transition: var(--transition);
      }
    }
  }
}
