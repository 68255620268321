.about {
  // border: 2px solid red;
  // height: 90vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 40px;
  align-items: center;

  background: rgb(226, 221, 228);

  & .top {
    // border: 2px solid red;
    height: 60vh;
    width: 100%;
    background-repeat: no-repeat;
    background-size: 100%;
    color: white;
    position: relative;
    // z-index: 1;
    padding: 40px var(--padding-main);
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 30px;

    @media (max-width: $breakpoint-tablet) {
      padding: 40px var(--padding-tablet);
    }

    &::before {
      content: "";
      position: absolute;
      height: 100%;
      width: 100%;
      z-index: 2;
      // background: rgba(0, 0, 0, 0.4);
      top: 0;
      left: 0;
      background: rgb(46, 54, 143);
      background: linear-gradient(
        90deg,
        rgba(46, 54, 143, 0.6) 10%,
        rgba(0, 0, 0, 0.7) 100%
      );
    }
    & .image {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;

      & img {
        object-fit: cover;
      }
    }

    & h3 {
      position: relative;
      z-index: 99999;
      font-size: 49px;
      width: 50%;
      transform: translateX(0);
      opacity: 1;
      transition: var(--transition-text);

      @media (max-width: $breakpoint-large) {
        width: 70%;
        font-size: 45px;
      }
      @media (max-width: $breakpoint-tablet) {
        width: 90%;
        font-size: 40px;
      }
      @media (max-width: $breakpoint-small) {
        width: 90%;
        font-size: 37px;
      }
      @media (max-width: $breakpoint-mobile) {
        width: 90%;
        font-size: 30px;
      }
    }

    & p {
      z-index: 99999;
      position: relative;
      font-size: 18px;
      display: flex;
      align-items: center;
      gap: 6px;
      transform: translateX(0);
      opacity: 1;
      transition: var(--transition-text-slow);

      @media (max-width: $breakpoint-tablet) {
        font-size: 16px;
      }
      @media (max-width: $breakpoint-small) {
        font-size: 14px;
      }
      @media (max-width: $breakpoint-mobile) {
        font-size: 12px;
      }
    }
  }

  & .translated {
    & h3 {
      transform: translateY(70%);
      opacity: 0;
      transition: var(--transition-text);
    }
    & p {
      transform: translateY(85%);
      opacity: 0;
      transition: var(--transition-text-slow);
    }
  }

  & h2 {
    font-size: 2rem;
    color: var(--primary-blue);
    // letter-spacing: 2px;
  }

  & .group {
    // border: 2px solid red;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 40px;
    padding: 40px var(--padding-main);
    grid-auto-rows: 1fr;
    // align-items: center;

    @media (max-width: $breakpoint-large) {
      display: flex;
      // align-items: center;
      justify-content: center;
      flex-wrap: wrap;
    }
    @media (max-width: $breakpoint-tablet) {
      display: flex;
      flex-wrap: wrap;
    }
  }

  & .center {
    // width: 90%;
    // border: 2px solid blue;
    background: white;
    padding: 40px;
    border-radius: 25px;
    // height: 180px;
    display: flex;
    flex-direction: column;
    // align-items: center;
    // justify-content: center;
    gap: 30px;
    // box-shadow: 1px 1px 5px 1px rgba(109, 109, 109, 0.6);
    transition: var(--transition-slow);
    border: 1px solid var(--primary-purple-400);
    max-width: 520px;

    @media (max-width: $breakpoint-large) {
      width: 400px;
      height: 290px;
      gap: 28px;
    }
    @media (max-width: $breakpoint-tablet) {
      padding: 30px;
      width: 400px;
      height: 260px;
      gap: 26px;
    }
    @media (max-width: $breakpoint-small) {
      padding: 25px;
      width: 300px;
      height: 265px;
      gap: 24px;
    }
    @media (max-width: $breakpoint-mobile) {
      padding: 20px;
      width: 300px;
      height: 210px;
      gap: 22px;
    }

    & h3 {
      font-size: 25px;
      // border: 2px solid red;
      color: var(--primary-blue);
      // letter-spacing: 2px;
      text-align: center;

      @media (max-width: $breakpoint-large) {
        font-size: 22px;
      }
      @media (max-width: $breakpoint-tablet) {
        font-size: 19px;
      }
      @media (max-width: $breakpoint-mobile) {
        font-size: 16px;
      }
    }

    & p {
      text-align: center;
      // letter-spacing: 1px;
      line-height: 30px;
      font-size: 18px;
      text-align: center;

      @media (max-width: $breakpoint-large) {
        line-height: 25px;
        font-size: 16px;
      }
      @media (max-width: $breakpoint-tablet) {
        line-height: 22px;
        font-size: 14px;
      }
      @media (max-width: $breakpoint-mobile) {
        line-height: 20px;
        font-size: 12px;
      }
    }

    // &:hover {
    //   box-shadow: none;
    //   transition: var(--transition-slow);
    // }
  }

  & .center-spaced {
    gap: 50px;

    @media (max-width: $breakpoint-large) {
      gap: 45px;
    }
    @media (max-width: $breakpoint-tablet) {
      gap: 40px;
    }
    @media (max-width: $breakpoint-small) {
      gap: 35px;
    }
  }

  & .values {
    & ul {
      list-style-type: none;
      // border: 2px solid red;
      display: grid;
      grid-template-columns: 1fr 1fr;
      flex-direction: column;
      gap: 10px;

      & li {
        font-size: 18px;
        // letter-spacing: 1px;
        display: flex;
        gap: 10px;
        align-items: center;

        @media (max-width: $breakpoint-tablet) {
          font-size: 16px;
        }
        @media (max-width: $breakpoint-tablet) {
          font-size: 14px;
        }

        & span {
          color: var(--primary-blue);
          font-size: 25px;

          @media (max-width: $breakpoint-tablet) {
            font-size: 22px;
          }
          @media (max-width: $breakpoint-tablet) {
            font-size: 18px;
          }
        }
      }
    }
  }
}

.value-proposition {
  padding: 90px var(--padding-main);
  display: flex;
  flex-direction: column;
  gap: 60px;
  background: var(--primary-purple-light);
  align-items: center;
  justify-content: center;

  @media (max-width: $breakpoint-tablet) {
    gap: 60px;
  }
  @media (max-width: $breakpoint-small) {
    gap: 40px;
  }

  & h1 {
    font-size: 35px;
    font-weight: 700;
    color: var(--primary-blue);
    letter-spacing: 1px;
    text-align: center;

    @media (max-width: $breakpoint-tablet) {
      font-size: 32px;
    }
    @media (max-width: $breakpoint-small) {
      font-size: 30px;
    }
  }

  & .values {
    // border: 2px solid blue;
    width: fit-content;
    display: grid;
    // grid-template-columns: auto auto;
    grid-template-columns: repeat(4, 1fr);
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    gap: 15px;
    row-gap: 40px;
    // padding: 0 var(--padding-main);

    @media (max-width: $breakpoint-tablet) {
      display: flex;
      gap: 25px;
      flex-wrap: wrap;
    }
    @media (max-width: $breakpoint-small) {
      gap: 20px;
    }

    & .value-center {
      // border: 2px solid red;
      display: flex;
      // justify-content: center;
      width: 270px;
      max-width: 350px;
      height: 180px;

      @media (max-width: $breakpoint-tablet) {
        width: 300px;
      }
    }

    & .value {
      // border: 2px solid green;
      display: flex;
      flex-direction: column;
      // border: 1px solid var(--primary-blue);
      align-items: center;
      // justify-content: center;
      padding: 20px;
      gap: 30px;
      font-size: 18px;
      font-weight: 700;
      width: 100%;

      @media (max-width: $breakpoint-tablet) {
        font-size: 16px;
      }
      @media (max-width: $breakpoint-small) {
        font-size: 14px;
      }

      & span {
        width: max-content;
        // height: 50px;
        border-radius: 50%;
        background: var(--primary-purple);
        padding: 10px;
        display: flex;
        align-items: center;
        color: white;
        font-weight: 700;
        font-size: 40px;

        @media (max-width: $breakpoint-tablet) {
          width: 30px;
          height: 30px;
        }
        @media (max-width: $breakpoint-small) {
          width: 20px;
          height: 20px;
        }
      }

      & p {
        letter-spacing: 1px;
        text-align: center;
      }
    }
  }
}

.service-offering {
  // border: 2px solid red;
  // height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 50px;
  padding: 30px var(--padding-main) 80px;
  background: var(--primary-purple-light);
  justify-content: center;
  align-items: center;

  & hr {
    border: none;
    width: 1px;
    // height: 1px;
    background: white;

    @media (max-width: $breakpoint-tablet) {
      // flex-direction: column;
      height: 1px;
      width: 100%;
    }
    @media (max-width: $breakpoint-tablet) {
      display: none;
    }
    @media (max-width: $breakpoint-small) {
    }
  }

  & h1 {
    font-size: 35px;
    color: var(--primary-blue);
    text-align: center;
    text-transform: capitalize;
    letter-spacing: 1px;
    font-weight: 700;

    @media (max-width: $breakpoint-large) {
      font-size: 32px;
    }
    @media (max-width: $breakpoint-small) {
      font-size: 30px;
    }
  }

  & .center {
    // border: 2px solid blue;
    // display: grid;
    display: flex;
    // flex-wrap: wrap;
    // jus
    // grid-template-columns: 1fr 1fr 1fr;
    gap: 25px;
    width: fit-content;

    @media (max-width: $breakpoint-tablet) {
      gap: 35px;
      flex-wrap: wrap;
      justify-content: center;
    }
    @media (max-width: $breakpoint-small) {
      flex-direction: column;
    }

    & .item {
      border: 1px solid var(--primary-blue);
      font-size: 18px;
      line-height: 30px;
      width: 330px;
      max-width: 320px;
      height: 380px;
      background-size: cover;
      background-repeat: no-repeat;
      display: flex;
      justify-content: flex-end;
      flex-direction: column;

      & p {
        // border: 2px solid red;
        // text-align: justify;
        // letter-spacing: 1px;
        height: 50%;
        background: rgba(0, 0, 0, 0.7);
        color: white;
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 15px;
        font-size: 14px;

        & hr {
          width: 50px;
          height: 3px;
          border-radius: 3px;
        }

        @media (max-width: $breakpoint-large) {
          font-size: 14px;
        }
        @media (max-width: $breakpoint-small) {
          font-size: 12px;
        }
      }
    }
  }

  & h2 {
    display: flex;
    gap: 10px;
  }

  & span {
    color: var(--primary-blue);
    // background: var(--primary-blue);
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 22px;
  }
}
