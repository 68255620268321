.section-four {
  // height: 70vh;
  // border: 2px solid red;
  display: flex;
  justify-content: center;
  padding: 40px var(--padding-main);
  flex-direction: column;
  gap: 40px;
  align-items: center;
  // background: rgba(203, 108, 230, 0.02);
  // background: var(--primary-purple-light);
  background: rgb(226, 221, 228);

  @media (max-width: $breakpoint-tablet) {
    padding: 30px var(--padding-tablet);
  }
  @media (max-width: $breakpoint-small) {
    padding: 30px var(--padding-small);
  }

  & h1 {
    font-size: 30px;
    color: var(--primary-blue);
    text-align: center;

    @media (max-width: $breakpoint-tablet) {
      font-size: 25px;
    }
  }

  & .center {
    width: 70%;
    max-width: 700px;
    // border: 2px solid blue;
    display: flex;
    flex-direction: column;
    gap: 15px;

    @media (max-width: $breakpoint-large) {
      width: 100%;
    }
    @media (max-width: $breakpoint-small) {
      width: 100%;
    }

    & .item {
      // border: 2px solid green;
      border-radius: 10px;
      background: white;
      display: flex;
      flex-direction: column;
      // gap: 40px;

      & .question {
        border-radius: 10px;
        // box-shadow: 1px 1px 3px 2px rgb(180, 179, 179);
        border: 1px solid var(--primary-purple);
        padding: 20px 40px;
        cursor: pointer;
        color: var(--primary-blue);
        font-weight: 400;
        text-transform: capitalize;
        font-size: 20px;

        @media (max-width: $breakpoint-tablet) {
          padding: 15px 30px;
          font-size: 16px;
        }
        @media (max-width: $breakpoint-small) {
          padding: 13px 27px;
          font-size: 14px;
        }

        & p {
          // text-align: center;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }

      & .answer {
        height: 0;
        opacity: 0;
        padding: 0;
        // padding: 20px;
        font-size: 17px;
        letter-spacing: 1px;
        text-align: justify;
        transition: var(--transition);

        @media (max-width: $breakpoint-tablet) {
          font-size: 15px;
        }
        @media (max-width: $breakpoint-small) {
          font-size: 13px;
        }

        & p {
          display: none;
          // font-weight: 200;
        }
      }
    }

    & .active {
      & .answer {
        height: auto;
        opacity: 1;
        padding: 20px 40px;
        transition: var(--transition);

        & p {
          display: block;
        }
      }
    }
  }
}
