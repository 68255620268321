.newsletter {
  padding: var(--padding-main);
  display: flex;
  flex-direction: column;
  gap: 40px;
  background: white;

  @media (max-width: $breakpoint-tablet) {
    padding: var(--padding-tablet);
  }
  @media (max-width: $breakpoint-small) {
  }

  & h1 {
    font-size: 30px;
    color: var(--primary-blue);

    @media (max-width: $breakpoint-tablet) {
      font-size: 25px;
    }
    @media (max-width: $breakpoint-small) {
      font-size: 20px;
    }
  }

  & p {
    line-height: 30px;
    font-size: 17px;
    text-align: justify;

    @media (max-width: $breakpoint-tablet) {
      line-height: 25px;
      font-size: 13px;
    }
    @media (max-width: $breakpoint-small) {
      line-height: 20px;
      font-size: 10px;
    }
  }

  & form {
    display: grid;
    grid-template-columns: 1fr 1fr auto;
    gap: 30px;

    @media (max-width: $breakpoint-tablet) {
      grid-template-columns: 1fr 1fr;
    }
    @media (max-width: $breakpoint-small) {
      grid-template-columns: 1fr;
    }
    & input {
      padding: 10px 20px;
      font-size: 17px;

      @media (max-width: $breakpoint-tablet) {
        padding: 8px 18px;
        font-size: 15px;
      }
      @media (max-width: $breakpoint-small) {
        padding: 6px 15px;
        font-size: 13px;
      }
    }

    & button {
      padding: 10px 20px;
      border-radius: 10px;
      font-size: 17px;
      text-transform: capitalize;
      border: 1px solid var(--primary-purple);
      background: transparent;
      cursor: pointer;
      transition: var(--transition);
      background: var(--primary-purple);
      color: white;

      @media (max-width: $breakpoint-tablet) {
        padding: 8px 18px;
        border-radius: 8px;
        font-size: 15px;
      }
      @media (max-width: $breakpoint-small) {
        padding: 6px 15px;
        font-size: 13px;
        border-radius: 7px;
      }

      &:hover {
        color: black;
        background: white;
        transition: var(--transition);
      }
    }
  }
}
