.feedback {
  // border: 2px solid red;
  min-height: 100vh;
  background: white;
  padding: 80px var(--padding-main);
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 35px;

  @media (max-width: $breakpoint-tablet) {
    padding: 80px var(--padding-tablet);
  }
  // @media (max-width: $breakpoint-small) {
  //   padding: 80px var(--padding-small);
  // }

  & hr {
    border: none;
    background: rgba(209, 206, 206, 0.4);
    height: 1px;
  }

  & .heading {
    text-align: center;
    color: var(--primary-blue);
  }

  & .heading-text {
    width: 75%;
    font-size: 17px;
    text-align: justify;
    line-height: 30px;
    letter-spacing: 1px;

    @media (max-width: $breakpoint-large) {
      width: 100%;
    }
    @media (max-width: $breakpoint-tablet) {
      font-size: 15px;
      line-height: 25px;
      width: 100%;
    }
    @media (max-width: $breakpoint-small) {
      font-size: 13px;
    }
  }

  & .content {
    // border: 2px solid green;
    width: 75%;
    padding: 50px;
    box-shadow: 1px 1px 9px 2px rgb(238, 235, 235);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 40px;

    @media (max-width: $breakpoint-large) {
      width: 100%;
    }

    & .top,
    .opinion-options {
      // border: 2px solid blue;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 30px;

      @media (max-width: $breakpoint-small) {
        grid-template-columns: 1fr;
      }

      & .item {
        // border: 2px solid red;
        display: flex;
        flex-direction: column;
        gap: 10px;

        & label {
          color: rgba(0, 0, 0, 0.7);
          // padding: 10px;
        }

        & input,
        select {
          // padding: 0 10px 5px;
          padding-bottom: 10px;
          border: none;
          border-bottom: 1px solid var(--primary-blue);

          &:focus {
            outline: none;
          }
        }

        & label {
          font-size: 14px;
          @media (max-width: $breakpoint-tablet) {
            font-size: 12px;
          }
          @media (max-width: $breakpoint-small) {
            font-size: 10px;
          }
        }
      }
    }

    & .laboratories {
      // border: 2px solid red;
      display: flex;
      gap: 10px;
      flex-direction: column;

      & label {
        font-weight: 400;
        font-size: 14px;
        padding-bottom: 5px;
        border-bottom: 1px solid var(--primary-purple);
        width: fit-content;
        text-transform: uppercase;
        color: var(--primary-blue);

        @media (max-width: $breakpoint-tablet) {
          font-size: 12px;
        }
        @media (max-width: $breakpoint-small) {
          font-size: 10px;
        }
      }

      & .item {
        display: flex;
        gap: 5px;
        font-size: 14px;

        @media (max-width: $breakpoint-tablet) {
          font-size: 12px;
        }
        @media (max-width: $breakpoint-small) {
          font-size: 10px;
        }
      }
    }

    & .diagnostics,
    .quickly,
    .motivates,
    .result,
    .opinion {
      // border: 2px solid red;
      display: flex;
      flex-direction: column;
      gap: 25px;

      & .label {
        font-weight: 400;
        font-size: 14px;
        padding-bottom: 5px;
        // border-bottom: 1px solid var(--primary-purple);
        width: fit-content;
        text-transform: uppercase;
        color: var(--primary-blue);

        @media (max-width: $breakpoint-tablet) {
          font-size: 12px;
        }
        @media (max-width: $breakpoint-small) {
          font-size: 10px;
        }
      }

      & select {
        padding-bottom: 10px;
        border: none;
        border-bottom: 1px solid var(--primary-blue);

        @media (max-width: $breakpoint-tablet) {
          font-size: 12px;
        }
        @media (max-width: $breakpoint-small) {
          font-size: 10px;
        }

        &:focus {
          outline: none;
        }
      }
    }

    & .quickly,
    .motivates,
    .result,
    .opinion {
      & .label {
        border-bottom: 1px solid var(--primary-purple);
        text-transform: uppercase;
      }
    }

    & .motivates,
    .result {
      & .options {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 10px;
        row-gap: 20px;

        @media (max-width: $breakpoint-tablet) {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
        }
        @media (max-width: $breakpoint-small) {
          font-size: 10px;
        }

        & .item {
          display: flex;
          gap: 5px;
          font-size: 14px;
          align-items: center;

          @media (max-width: $breakpoint-tablet) {
            font-size: 12px;
          }
          @media (max-width: $breakpoint-small) {
            font-size: 10px;
          }

          @media (max-width: $breakpoint-small) {
            min-width: 45%;
          }
        }
      }
    }

    & .issue {
      display: flex;
      gap: 20px;
      font-size: 14px;
      align-items: center;

      @media (max-width: $breakpoint-tablet) {
        font-size: 12px;
      }
      @media (max-width: $breakpoint-small) {
        font-size: 10px;
      }

      & div {
        display: flex;
        gap: 5px;
        align-items: center;
      }
    }

    & .response,
    .patronage {
      display: flex;
      flex-direction: column;
      gap: 20px;

      & label {
        width: fit-content;
        border-bottom: 1px solid var(--primary-purple);
        padding-bottom: 5px;
        color: var(--primary-blue);
        text-transform: uppercase;
        font-size: 14px;

        @media (max-width: $breakpoint-tablet) {
          font-size: 12px;
        }
        @media (max-width: $breakpoint-small) {
          font-size: 10px;
        }
      }
      & select,
      textarea {
        border: none;
        padding-bottom: 10px;
        border-bottom: 1px solid var(--primary-blue);
        resize: vertical;

        @media (max-width: $breakpoint-tablet) {
          font-size: 12px;
        }
        @media (max-width: $breakpoint-small) {
          font-size: 10px;
        }

        &:focus {
          outline: none;
        }
      }
    }

    & button {
      padding: 10px;
      font-size: 16px;
      letter-spacing: 1px;
      font-weight: 500;
      font-weight: 400;
      text-transform: uppercase;
      border: 1px solid var(--primary-purple);
      transition: var(--transition);
      border-radius: 7px;
      cursor: pointer;
      color: white;
      background: var(--primary-purple);

      @media (max-width: $breakpoint-tablet) {
        font-size: 14px;
      }
      @media (max-width: $breakpoint-small) {
        font-size: 12px;
      }

      &:hover {
        background: white;
        color: black;
        transition: var(--transition);
      }
    }
  }
}
