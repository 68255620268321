.nav-bar {
  //   border: 2px solid red;
  // padding-left: 90px;
  // padding-right: 90px;
  padding: 0 var(--padding-main);
  height: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  //   gap: 40px;
  justify-content: space-between;
  background: var(--primary-white);
  box-shadow: 2px 1px 10px 0.5px rgb(143, 141, 141);
  position: sticky;
  z-index: 9999999;
  top: 60px;
  left: 0;

  @media (max-width: $breakpoint-tablet) {
    top: 0;
    padding: 0 var(--padding-tablet);
    position: relative;
    width: 100vw;
  }
  @media (max-width: $breakpoint-large) {
    // justify-content: initial;
    gap: 20px;
  }

  & .logo {
    height: 55px;
    width: 160px;

    @media (max-width: $breakpoint-large) {
      height: 47px;
      width: 150px;
    }
    @media (max-width: $breakpoint-tablet) {
      height: 35px;
      width: 100px;
    }

    & img {
      // height: 100%;
      // width: 100%;
      height: 55px;
      width: 160px;
      @media (max-width: $breakpoint-large) {
        height: 47px;
        width: 150px;
      }
      @media (max-width: $breakpoint-tablet) {
        height: 35px;
        width: 100px;
      }
    }
  }

  & .right {
    // border: 2px solid green;
    display: flex;
    gap: 50px;
    transition: var(--transition-slow);
    // height: auto;

    @media (max-width: $breakpoint-large) {
      gap: 20px;
    }
    @media (max-width: $breakpoint-tablet) {
      position: absolute;
      top: 100%;
      left: 0;
      flex-direction: column;
      height: 0;
      width: 100%;
      padding: 0;
      background: white;
      transition: var(--transition-slow);
      gap: 40px;
    }

    & a {
      text-decoration: none;
      color: var(--primary-blue);
      font-weight: 500;
      font-size: 14px;
      font-weight: 700;
    }
    & a:hover {
      transition: var(--transition);
      color: var(--primary-purple);
    }

    & .packages {
      position: relative;
      display: flex;
      align-items: center;

      & p {
        color: var(--primary-blue);
        font-size: 14px;
        font-weight: 700;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 5px;
      }

      & span {
        // border: 2px solid red;
        position: absolute;
        // height: 100%;
        width: 120%;
        background: white;
        top: 58.7px;
        left: 50%;
        box-shadow: 1px 1px 3px 0px grey;
        // padding: 20px 0px;
        // display: grid;
        grid-template-columns: 1fr;
        // gap: 10px;
        flex-direction: column;
        text-transform: capitalize;
        align-items: center;
        justify-content: center;
        margin-left: -60%;
        color: var(--primary-blue);
        font-size: 14px;
        font-weight: 700;
        display: none;
        opacity: 0;
        height: 0;

        @media (max-width: $breakpoint-tablet) {
          z-index: 999999;
          background: white;
          width: 100%;
        }

        & a {
          // font-size: ;
          padding: 15px;
          // display: flex;

          width: 100%;
          text-align: center;
          cursor: pointer;
          background: white;

          &:hover {
            background: var(--primary-blue);
            color: white;
          }
        }
      }

      & .open {
        // height:
        display: grid;
        opacity: 1;
        height: auto;
      }
    }

    & a,
    .packages {
      @media (max-width: $breakpoint-tablet) {
        opacity: 0;
        transition: var(--transition-slow);
        transform: translateX(-100%);
      }
    }
  }

  & .menu-open {
    height: fit-content;
    transition: var(--transition-slow);

    @media (max-width: $breakpoint-tablet) {
      padding: 40px var(--padding-tablet);
    }
    @media (max-width: $breakpoint-small) {
      padding: 40px var(--padding-tablet);
    }
    & a,
    .packages {
      opacity: 1;
      transition: var(--transition-slow);
      transform: translateX(0);
    }
  }

  & .menu-logo {
    display: none;
    font-size: 25px;
    cursor: pointer;
    color: var(--primary-blue);

    @media (max-width: $breakpoint-tablet) {
      display: block;
    }
  }
}

.pre-nav {
  height: 60px;
  width: 100%;
  background: var(--primary-purple);
  display: flex;
  align-items: center;
  color: white;
  padding: 20px var(--padding-main);
  // justify-content: space-between;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 99999999;
  font-size: 12px;

  @media (max-width: $breakpoint-large) {
    font-size: 10px;
  }
  @media (max-width: $breakpoint-tablet) {
    padding: 20px var(--padding-tablet);
  }

  & .center {
    display: flex;
    gap: 40px;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  & .contacts {
    // border: 2px solid red;
    height: 100%;
    // background: var(--primary-blue);
    display: flex;
    gap: 30px;
    @media (max-width: $breakpoint-tablet) {
      display: none;
    }

    & .item {
      display: flex;
      // border: 2px solid green;
      align-items: center;
      gap: 5px;

      & span {
        padding: 5.5px;
        color: var(--primary-blue);
        background: white;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
      }

      & p {
        font-size: 11px;
        letter-spacing: 1px;
      }
      & .head {
        color: var(--primary-purple);
      }
    }

    & .location {
      @media (max-width: $breakpoint-large) {
        display: none;
      }
    }

    & .address {
      p {
        @media (max-width: $breakpoint-xlarge) {
          width: 80%;
        }
        @media (max-width: $breakpoint-large) {
          width: 100%;
        }
      }
    }
  }
  & .right {
    display: flex;
    align-items: center;
    gap: 40px;

    @media (max-width: $breakpoint-tablet) {
      // display: none;
      width: 100%;
      justify-content: space-between;
    }

    & .socials {
      font-size: 18px;
      display: flex;
      gap: 15px;
      align-items: center;

      @media (max-width: $breakpoint-large) {
        font-size: 16px;
      }
      @media (max-width: $breakpoint-tablet) {
        font-size: 14px;
      }

      & a {
        color: white;
        cursor: pointer;
      }
    }
  }
  & a {
    text-decoration: none;
  }
  & button {
    // padding: 15px;
    width: 120px;
    height: 40px;
    color: var(--primary-blue);
    background: white;
    border: none;
    font-size: 14px;
    border-radius: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: var(--transition);
    font-weight: 700;
    letter-spacing: 1px;

    @media (max-width: $breakpoint-large) {
      font-size: 13px;
      width: 110px;
      height: 35px;
      border-radius: 17px;
    }
    @media (max-width: $breakpoint-tablet) {
      font-size: 11px;
      width: 95px;
      height: 30px;
      border-radius: 14px;
    }

    &:hover {
      // opacity: 0.9;
      color: white;
      background: var(--primary-purple);
      transition: var(--transition);
      border: 2px solid white;
    }
  }

  & p {
    display: flex;
    align-items: center;
    gap: 5px;
  }
}
